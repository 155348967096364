import {useTranslation} from 'react-i18next';
import {FC, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import {CheckboxChangeEvent} from 'antd/lib/checkbox/Checkbox';
import dayjs from 'dayjs';
import Container from '../../../components/layout/Container';
import {FranchiseChangePaths} from '../../paths';
import {BaseInsurance, BaseInsuranceType, BaseInsuranceTypes} from '../../../models/base-insurance';
import {useStore} from '../../../hooks/useStore';
import StepsNavigationButtons from '../../../components/StepsNavigationButtons';
import Text from '../../../components/shared/Text';
import Space from '../../../components/shared/Space';
import {Col, Row} from '../../../components/layout/Grid';
import Checkbox from '../../../components/shared/Checkbox';
import Info from '../../../components/shared/Info';
import {GUTTER_SIZE} from '../../../config';
import {BASE_INSURANCE_PREMIUM_REDUCTION_FACTOR} from '../../../stores/calculator-store';
import FranchiseSelector from '../../../components/FranchiseSelector';
import BaseInsuranceCardProduct from '../../../components/cards/BaseInsuranceCardProduct';
import '../../calculator/product/BaseInsuranceSelection.scss';

export const CurrentFranchise: FC = observer(() => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const store = useStore();
  const person = store.franchiseChangeStore.current;
  const [selectedType, setSelectedType] = useState(person.baseInsurance?.type || BaseInsuranceType.STANDARD);
  const [selectedFranchise, setSelectedFranchise] = useState(person.baseInsurance?.oldFranchiseGroup?.id || 1);
  const [withAccident, setWithAccident] = useState(person.baseInsurance?.withAccident || false);
  const hasReduced = store.franchiseChangeStore.getPremiumReductionBaseInsurance(person);
  const factor = hasReduced ? BASE_INSURANCE_PREMIUM_REDUCTION_FACTOR : 1;

  useEffect(() => {
    const baseInsurance = new BaseInsurance();
    baseInsurance.withAccident = withAccident;
    baseInsurance.type = selectedType;
    baseInsurance.setRate(
      store.baseInsuranceRateStore.findOne(
        selectedType,
        selectedFranchise,
        person.age,
        store.franchiseChangeStore.city?.regionCode || '',
        person.insuranceStart
      )
    );
    baseInsurance.setFranchiseGroup(person.baseInsurance?.franchiseGroup);
    baseInsurance.setOldFranchiseGroup(store.franchiseGroupStore.getById(selectedFranchise));
    baseInsurance.setOldRate(
      store.baseInsuranceRateStore.findOne(
        selectedType,
        selectedFranchise,
        person.age,
        store.franchiseChangeStore.city?.regionCode || '',
        dayjs().startOf('month')
      )
    );
    person.setBaseInsurance(baseInsurance);
  }, [selectedType, withAccident, selectedFranchise]);

  const onChange = (e: CheckboxChangeEvent) => setWithAccident(e.target.checked);

  const previousStep = () => {
    if (store.editMode) {
      store.setEditMode(false);
      navigate(FranchiseChangePaths.overview());
    } else {
      navigate(FranchiseChangePaths.index());
    }
  };

  const nextStep = () => {
    store.setEditMode(false);
    navigate(FranchiseChangePaths.new());
  };

  const getPrice = (type: BaseInsuranceType): number => {
    const rate = store.baseInsuranceRateStore.findOne(
      type,
      selectedFranchise,
      person.age,
      store.franchiseChangeStore.city?.regionCode || '',
      dayjs().startOf('month')
    );

    if (rate) {
      const environmentalTax = rate.premiumVersion?.environmentalTax || 0;

      if (withAccident) {
        return rate.valueWithAccident * factor - environmentalTax;
      }

      return rate.valueWithoutAccident * factor - environmentalTax;
    }

    return 0;
  };

  return (
    <>
      <Container background={'white'} className={'base-insurance-selection'}>
        <div className={'title-wrapper'}>
          <Text header={1}>{t('stepCurrentFranchise.title')}</Text>
          <Text size={'intro'}>{t('stepCurrentFranchise.headerInfo')}</Text>
        </div>

        <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <FranchiseSelector
              isChild={person.isChild}
              selectedFranchiseGroup={selectedFranchise}
              setSelectedFranchiseGroup={setSelectedFranchise}
            />
          </Col>
        </Row>
      </Container>
      <Container background={'light'} flex={1}>
        <Space fullWidth direction={'vertical'} size={'large'}>
          <Space direction={'horizontal'} size={'large'} align={'baseline'}>
            <Checkbox checked={withAccident} onChange={onChange}>{t('baseInsurance.withAccident.label')}</Checkbox>
            <Info className={'franchise-info'}>
              {t('baseInsurance.withAccident.info')}
            </Info>
          </Space>
          <Text header={4}>{t('stepCurrentFranchise.cardInfo')}</Text>
          <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]}>
            {Object.keys(BaseInsuranceTypes).map((key) =>
              getPrice(key as BaseInsuranceType) > 0 && (
                <Col key={key} xs={24} sm={24} md={24} lg={12} xl={8}>
                  <BaseInsuranceCardProduct
                    key={key}
                    type={key as BaseInsuranceType}
                    selected={selectedType === key}
                    onSelectionChange={() => setSelectedType(key as BaseInsuranceType)}
                    price={getPrice(key as BaseInsuranceType)}
                    reduced
                  />
                </Col>
              ))}
          </Row>
          <StepsNavigationButtons backHidden={store.editMode} back={() => previousStep()} next={() => nextStep()}/>
        </Space>
      </Container>
    </>
  );
});
