import React, {useEffect, useState} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {Spin} from 'antd';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../hooks/useStore';
import {FranchiseChangePaths} from '../paths';
import {FranchiseChange} from './person/FranchiseChange';
import {CurrentFranchise} from './current/CurrentFranchise';
import {NewFranchise} from './new/NewFranchise';
import SuccessSubmission from './success/SuccessSubmission';
import {Config} from '../../config';
import PersonDetails from './person-details/PersonDetails';
import Overview from './overview/Overview';
import NotFound from '../not-found/NotFound';

const FranchiseChangeRoutes = () => {
  const store = useStore();
  const navigate = useNavigate();
  const [isInitialLoad, setIsInitialLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const isStoreLoading = store.cityStore.isLoading
      || store.franchiseGroupStore.isLoading
      || store.baseInsuranceRateStore.isLoading
      || store.addonInsuranceRateStore.isLoading
      || store.premiumVersionStore.isLoading
      || store.calculatorStore.isLoading
      || store.franchiseChangeStore.isLoading
      || store.travelInsuranceTypeStore.isLoading
      || store.termOfInsuranceStore.isLoading
      || store.travelInsuranceRateStore.isLoading
      || store.travelInsuranceStore.isLoading;

    if (!isStoreLoading) {
      if (isInitialLoad) {
        store.baseInsuranceRateStore.models.forEach((m) => {
          m.setPremiumVersion(store.premiumVersionStore.getById(m.premiumVersionId));
        });
        store.addonInsuranceRateStore.models.forEach((m) => {
          m.setPremiumVersion(store.premiumVersionStore.getById(m.premiumVersionId));
        });

        if (Config.franchiseChangeServiceDisabled) {
          navigate('/franchise');
          setIsLoading(false);
          return;
        }
        if (store.franchiseChangeStore.loadFromSession()) {
          navigate(FranchiseChangePaths.overview());
          setIsLoading(false);
        } else {
          store.franchiseChangeStore.reset();
          navigate(FranchiseChangePaths.index());
          setIsLoading(false);
        }
      }

      setIsInitialLoad(false);
    }
  }, [
    store.cityStore.isLoading,
    store.franchiseGroupStore.isLoading,
    store.baseInsuranceRateStore.isLoading,
    store.addonInsuranceRateStore.isLoading,
    store.premiumVersionStore.isLoading,
    store.franchiseChangeStore.isLoading,
    store.travelInsuranceTypeStore.isLoading,
    store.termOfInsuranceStore.isLoading,
    store.travelInsuranceRateStore.isLoading,
    store.travelInsuranceStore.isLoading,
    isInitialLoad,
  ]);

  useEffect(() => {
    setIsInitialLoad(true);
  }, []);

  if (isLoading) {
    return <Spin style={{marginTop: 30, marginBottom: 30}}/>;
  }

  return (
    <Routes>
      <Route index element={<FranchiseChange/>}/>
      <Route path={'current'} element={<CurrentFranchise/>}/>
      <Route path={'new'} element={<NewFranchise/>}/>
      <Route path={'person'} element={<PersonDetails/>}/>
      <Route path={'overview'} element={<Overview/>}/>
      <Route path={'overview/success'} element={<SuccessSubmission/>}/>
      <Route path={'*'} element={<NotFound/>}/>
    </Routes>
  );
};

export default observer(FranchiseChangeRoutes);
